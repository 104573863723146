import React, { useEffect, useState } from "react";
import logo from './con-certeza.jpeg';
import './App.css';
import * as microsoftTeams from "@microsoft/teams-js";
import jwt_decode from "jwt-decode";
import Button from '@material-ui/core/Button';
import ModalDialog from './ModalDialog';

function App() {
  const [teamsContext, setTeamsContext] = useState("");
  const [teamsToken, setTeamsToken] = useState("");
  const [email, setEmail] = useState("");
  const [register, setRegister] = useState(true);
  const [decoded, setDecoded] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  // function to handle modal close
  const handleClose = () => {
    setOpen(false);
  };

  function startTeams() {
    microsoftTeams.initialize();
    console.log("Initizalized")
    microsoftTeams.getContext(function (context) {
      console.log("Context:")
      console.log(context)
      setTeamsContext(context);
      setEmail(context.loginHint);
    })

    var authTokenRequest = {
      successCallback: function (result) {
        const url = 'https://app.con-certeza.mx/sso/teams/' + result;
        window.location.replace(url);
        /* console.log("Success: " + result);
        setTeamsToken(result);
        let d=jwt_decode(result);
        setDecoded(d);
        console.log("Decoded: " + JSON.stringify(d)); */
      },
      failureCallback: function (error) { console.log("Error getting token: " + error); }
    };
    microsoftTeams.authentication.getAuthToken(authTokenRequest);
  }

  useEffect(() => {
    console.log("Entro");
    startTeams();
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="logo" width={420} />
        <p>
          <font color="black">{decoded ? decoded.name : ""}</font>
          <br />
          <font color="gray">{email ? email : ""}</font>
        </p>
        {/*
        <a
          className="App-link"
          href="https://app.con-certeza.mx/"
        >
          Ingresar
        </a>
      */}
        {register ? (
          <>
            <Button variant="contained" color="primary" onClick={handleOpen}>
              Regístrate
            </Button>
            <ModalDialog
              open={open}
              handleClose={handleClose}
              email={email ? email : "Undefined"}
              name={decoded ? decoded.name : "Undefined"}
            />
          </>
        ) : null}

      </header>
    </div>
  );
}

export default App;
