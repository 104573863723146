import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
//import FormGroup from '@mui/material/FormGroup';
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),

    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '300px',
    },
    '& .MuiButtonBase-root': {
      margin: theme.spacing(2),
    },
  },
}));

const Form = ({ handleClose, email, name }) => {
  const classes = useStyles();
  // create state variables for each input
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  //const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [empresa, setEmpresa] = useState('');
  const [ciudad, setCiudad] = useState('');
  const [celular, setCelular] = useState('');
  const [state, setState] = React.useState({
    checkedA: false,
    checkedB: false,
  });
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  console.log("Form",email,name);

/*
  var names = name.split(' ')
  if (names.length == 2) {
    setFirstName(names[0])
    setLastName(names[1])
  }
  else if (names.length == 4) {
    setFirstName(names[0]+" "+names[1])
    setLastName(names[2]+" "+names[3])
  }
  else {
    setFirstName(names[0])
    setLastName(names[names.length - 1])
  }
  */

  const handleSubmit = e => {
    e.preventDefault();
    console.log("Form");
    handleClose();
  };

  const removerCaracteres = (event) => {
    let phone = event.target.value;
    if (phone) {
      let wordArray = phone.split("");
      let result = "";
      if (wordArray) {
        wordArray.map((item, i) => {
          if (esNumero(wordArray[i])) {
            result += wordArray[i];
          }
        });
        return result;
      } else {
        return false;
      }
    }
    return false;
  };
  function esNumero(text) {
    if (text) {
      var reg = new RegExp("[0-9]+$");
      if (reg.test(text)) {
        return reg.test(text);
      } else {
        return false;
      }
    }
    return false;
  }

  let disableform = true;
  if (
    ciudad.length >= 2 &&
    celular.length >= 8 &&
    empresa.length >= 3 &&
    state.checkedA == true &&
    state.checkedB == true 
  ) {
    disableform = false;
  }
  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <TextField
        label="Nombre"
        variant="filled"
        required
        value={name}
        //onChange={e => setFirstName(e.target.value)}
        inputProps={
            { readOnly: true, }
        }
      />
      {/*
      <TextField
        label="Apellido"
        variant="filled"
        required
        value={lastName}
        //onChange={e => setLastName(e.target.value)}
        inputProps={
            { readOnly: true, }
        }
      />
        */}
      <TextField
        label="Email"
        variant="filled"
        type="email"
        required
        value={email}
        inputProps={
            { readOnly: true, }
        }
      />
      <TextField
        label="Empresa"
        variant="filled"
        required
        value={empresa}
        onChange={e => setEmpresa(e.target.value)}
      />
      <TextField
        label="Ciudad"
        variant="filled"
        required
        value={ciudad}
        onChange={e => setCiudad(e.target.value)}
      />
      <TextField
        label="Celular"
        variant="filled"
        required
        value={celular}
        //onChange={e => setCelular(e.target.value)}
        onChange={(e)=>{
            let resultado = removerCaracteres(e);
            setCelular(!resultado ? "" : resultado)
        }}
        inputProps={{ maxLength: 10 }}
      />
      {/*
      <TextField
        label="Password"
        variant="filled"
        type="password"
        required
        value={password}
        onChange={e => setPassword(e.target.value)}
      />
    */}
      <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.checkedA}
                      onChange={handleChange}
                      name="checkedA"
                      color="primary"
                    />
                  }
                  label="Acepto Términos y Condiciones"
                  style={{margin:"0"}}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.checkedB}
                      onChange={handleChange}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label={
                    <div>
                      Acepto{" "}
                      <a
                        href="https://certificaciondigital.online/aviso-de-privacidad/"
                        target="_blank"
                      >
                        Aviso de Privacidad
                      </a>
                    </div>
                  }
                  style={{margin:"0"}}
                />
              </FormGroup>
      <div>
        <Button variant="contained" onClick={handleClose}>
          Cancel
        </Button>
        <Button type="submit" variant="contained" color="primary" disabled={disableform}>
          Signup
        </Button>
      </div>
    </form>
  );
};

export default Form;